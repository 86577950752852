<template>
    <li>
        <item-card>
            <template v-slot:title>
                <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                    Pamatdati
                </h3>
                <template v-if="item.is_services">
                    <Badge text="Pakalpojums" className="bg-indigo-100 text-indigo-800 mr-2 mb-2 md:mb-0"/>
                </template>
            </template>

            <template v-slot:buttons>
        <span class="relative z-0 inline-flex rounded-md ml-3">

          
          <template v-if="this.$userCan('manage_catalog_items') && !displayForm('EditCatalogItemDetails')">
            <Button icon="pencil" @click="showForm('EditCatalogItemDetails')"/>
          </template>

        </span>
            </template>

            <template v-slot:content>

              <template v-if="!displayForm('EditCatalogItemDetails')">
                  <ShowCatalogItemDetails :item="item"/>
              </template>

              <template v-if="displayForm('EditCatalogItemDetails') && this.$userCan('manage_catalog_items')">
                  <EditCatalogItemDetails :item="item"/>
              </template>

              <div class="w-full grid grid-cols-12">
                <div class="flex-1 col-span-12 pt-4">
                  <template v-if="!displayForm('EditCatalogItemKnCodeWeightCountry') && this.$userCan('manage_catalog_items')">

                    <div class="flex items-center ">
                      <div class="sm:flex sm:space-x-10">
                        <ItemText title="KN kods" :text="item.kn_code ?? 'Nav norādīts'"/>
                        <ItemText title="Vidējais svars / kg" :text="item.weight ?? 'Nav norādīts'"/>
                        <ItemText title="Valsts" :text="item.country ? item.country.name : 'Nav norādīta'"/>
                      </div>

                      <div class="ml-auto">
                        <Button icon="pencil" @click="showForm('EditCatalogItemKnCodeWeightCountry')"/>
                      </div>
                    </div>

                  </template>

                  <template v-if="displayForm('EditCatalogItemKnCodeWeightCountry')">
                    <div class="flex h-full items-emd">
                      <EditCatalogItemKnCodeWeightCountry :item="item"/>
                    </div>
                  </template>
                </div>
              </div>

            </template>

        </item-card>
    </li>
</template>

<script>
import {mapGetters} from "vuex";
import ItemText from "@/components/Components/ItemText"
import {defineAsyncComponent} from 'vue'
import EditCatalogItemKnCodeWeightCountry from "@/components/Settings/Catalog/CatalogItem/EditCatalogItemKnCodeWeightCountry";

const ItemCard = defineAsyncComponent(() =>
    import('@/components/Components/ItemCard'))
const Badge = defineAsyncComponent(() =>
    import('@/components/Components/Badge'))
const ShowCatalogItemDetails = defineAsyncComponent(() =>
    import('@/components/Settings/Catalog/CatalogItem/ShowCatalogItemDetails'))
const EditCatalogItemDetails = defineAsyncComponent(() =>
    import('@/components/Settings/Catalog/CatalogItem/EditCatalogItemDetails'))

export default {
    name: "CatalogItemDetails",
    components: {
        ItemCard,
        ItemText,
        ShowCatalogItemDetails,
        EditCatalogItemDetails,
        EditCatalogItemKnCodeWeightCountry,
        Badge
    },
    data: () => ({
        showDetails: false,
    }),
    props: ['item'],
    computed: {
        ...mapGetters({
            formsForDisplay: 'formsForDisplay',
        })
    },
    methods: {
        showForm(formName) {
            this.$store.dispatch('addFormForDisplay', formName)
        },
        displayForm(formName) {
            return this.formsForDisplay.includes(formName)
        },
    }
}
</script>